.neon-card {
    position: relative;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: 0.2s ease-in-out;
    background: transparent;
    z-index: 0;
    cursor: pointer;
}

.neon-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    padding: 2px; 
    background: linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 1; /* Pseudo-elementi öne alın */
    pointer-events: none; /* Etkileşimleri engelle */
    transition: 0.3s ease-in-out;
}

.neon-card:hover {
    box-shadow: 0 0 4px #9C7338, 0 0 10px #9C7338, 0 0 20px #9C7338; /* Neon ışık efekti */
}
