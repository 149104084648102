.products-title {
  font-size: 60px; 
  font-weight: bold;
  text-align: center;
  background: linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338);
  -webkit-background-clip: text;
  color: transparent; 
  animation: slideIn 2s ease-out; 
  margin-top: 20px;
}

/* Animasyon: Yazının sağdan sola gelmesi */
@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slider container'ı için stil */
.products-slider-container {
  padding: 20px;
}

/* Swiper'ı için stil */
.mySwiper {
  padding: 20px;
}

/* Her bir ürün kartı */
.product-card {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 10px 10px 10px 10px;
  transition: transform 0.3s ease;
}

/* Ürün görseli */
.product-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 200px;
  max-width: 300px;
}

/* Ürün bilgileri */
.product-info {
  padding: 5px;
  text-align: center;
}

.product-title {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  margin: 0 0 15px;
}

/* Ürüne Git butonu */
.product-button {
  background-color: #9c7338;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-button:hover {
  background-color: #ebc48c;
}

/* Hover efekti: Kartın büyümesi */
.product-card:hover {
  transform: translateY(-10px);
}
@media (max-width: 1024px) {
  .products-title {
    font-size: 50px; /* Tablet ekranlar için küçültülmüş boyut */
  }
}

/* Mobil cihazlar için */
@media (max-width: 768px) {
  .products-title {
    font-size: 40px; /* Mobil ekranlar için daha küçük boyut */
  }
}

/* Daha küçük mobil cihazlar için */
@media (max-width: 480px) {
  .products-title {
    font-size: 30px; /* Küçük ekranlar için en küçük boyut */
  }
}