
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    text-decoration: none;
    bottom: 20px; /* Butonları slider'ın altına taşır */
    left: 40px;
    width: 50%;
    padding: 0 10px; /* Daha dar boşluk */
    z-index: 10;
  }
  
  .btn {
    font-size: 24px;
    cursor: pointer;
    
    background: rgba(0, 0, 0, 0); /* Başlangıçta daha yumuşak bir arka plan */
    border: none;
    color: #b0b0b0;
    padding: 10px;
    transition: color 0.3s ease, transform 0.2s ease; /* Transform geçişi eklenebilir */
  }
  
  .btn:hover {
    color: #1C427A; /* Renk değişimi isteğe bağlı */
    transform: scale(1.1); /* Hoverda butonu biraz büyütme efekti */
  }
  
  .progress-bar-wrapper {
    display: flex; /* Flexbox ile ortalamayı sağlayın */
    flex-grow: 1;

    align-items: center; /* Dikeyde ortalama */
    height: 5px; /* Progress bar yüksekliği */
    margin: 0 10px; /* Yan boşluk */
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background: linear-gradient(
      to right,
      #000000 0%,        /* Başlangıçta siyah */
      #11284A 16%,       /* 16% de #11284A rengi */
      #1C427A 50%,       /* 50% de #1C427A rengi */
      #0E213D 83%,       /* 83% de #0E213D rengi */
      #000000 100%       /* 100% de tekrar siyah */
    );
    width: 0%; /* Başlangıçta 0% genişlik */
    transition: width 0.5s linear;
  }
  
  
  .caption {
    position: absolute;
    bottom: 80px; /* Yazıyı slider altına yaklaştırır */
    left: 5%;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    z-index: 10;
  }
  
  .caption h1 {
    font-size: 24px;
    margin: 0;
  }
  
  .caption p {
    font-size: 16px;
    margin: 5px 0 0;
  }
  
  /* Görsel düzgün oturması için */
  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  @media (max-width: 900px) {
    .nav {
      left: 0;
      width: 90%; /* Genişliği %90 yapar */
    }
  }
  .responsive-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Varsayılan */
  }
  
  @media (max-width: 600px) { /* Telefonlar */
    .responsive-image {
      object-fit: contain;
    }
  }
  @media (max-width: 800px) {
    .nav {
      display: none;
    }
    .caption{
      display: none;
    }
  }