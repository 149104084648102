/* components/Footer.css */

.footer {
    background: linear-gradient(
    to bottom, 
    #000000 0%, 
    #11284A 84%, 
    #1C427A 20%, 
    #0E213D 16%, 
    #000000 100%
  );
    color: white;
    padding: 40px 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    margin: 0;
  }
  
  .footer-logo {
    text-align: left;
  }
  
  .footer-logo .logo {
    width: 120px;
    margin-bottom: 10px;
    /* Altın rengiyle gölge ve beyaza doğru geçiş */
    -webkit-filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.3)) drop-shadow(3px 3px 5px rgba(255, 255, 255, 0.5));
    filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.3)) drop-shadow(3px 3px 5px rgba(255, 255, 255, 0.5));
  }
  
  
  .footer-links {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 10px;
  }
  
  .footer-links li a {
    text-decoration: none;
    color: white;
    transition: color 0.3s;
  }
  
  .footer-links li a:hover {
    color: #1C427A; /* Hoverda mavi renk */
  }
  
  .social-icons {
    margin-top: 10px;
    display: flex;
    gap: 15px;
  }
  
  .social-icons .MuiSvgIcon-root {
    font-size: 32px;
  }
  
  .footer-bottom {
    margin-top: 40px;
    text-align: center;
  }
  
  /* Glass effect for buttons */
  .glass-btn {
    width: 50px;
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .glass-btn.blue-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.9);
  -moz-box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.9);
    box-shadow: 0px 20px 20px -10px rgba(0, 111, 255, 0.9);
  }
  
  .glass-btn.blue-btn:hover {
    -webkit-box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 1);
  -moz-box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 1);
    box-shadow: 0px 30px 40px -8px rgba(0, 111, 255, 1);
  }
  

  
  .glass-btn.red-btn {
    box-shadow: 0px 20px 20px -10px rgba(255, 16, 39, 0.9);
  }
  
  .glass-btn.red-btn:hover {
    box-shadow: 0px 30px 40px -8px rgba(255, 16, 39, 1);
  }
  

  
  .glass-btn.amber-btn {
    box-shadow: 0px 20px 20px -10px rgba(131, 58, 180, 0.9);
  }
  
  .glass-btn.amber-btn:hover {
    box-shadow: 0px 30px 40px -8px rgba(225, 48, 108, 1);
  }
  

  
  .glass-btn:hover {
    background-color: whitesmoke; /* Hoverda arka plan rengini açıyoruz */
    transform: translateY(-5px);
    transition: all 0.3s ease-in-out;
  }
  