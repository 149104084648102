.menu-item {
  position: relative;
}

.submenu {
  opacity: 0;  /* Başlangıçta görünmez */
  width: 150px;
  visibility: hidden;  /* Başlangıçta görünmez */
  position: absolute;
  background: linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);  /* Başlangıçta biraz yukarıda */
  transition: all 0.3s ease;  /* Geçiş süresi ve türü */
  z-index: -1;  /* Menü altındaki öğelerin üstünde kalmasını sağla */
  font-size: 16px;  /* Yazı tipi boyutu */
  border-radius: 15px;
}

.submenu a {
  text-decoration: none;  /* Alt çizgi olmaması için */
  color: #b0b0b0;  /* Yazı rengi */
  padding: 8px 16px;  /* İçerik etrafında biraz boşluk */
  display: block;  /* Her öğeyi bir satırda göstermek için */
  transition: color 0.3s ease;  /* Renk geçişi */
}
.submenu a .MuiTypography-root {
  font-family: 'Sansita', sans-serif !important;
}
.submenu a:hover {
  color: #1c427a;  /* Hover ile yazı rengini değiştirme */
  background-color: rgba(255, 255, 255, 0.1);  /* Hover arka plan rengi */
}


.menu-item:hover .submenu {
  opacity: 1;  /* Menü açıldığında görünür */
  visibility: visible;  /* Görünür hale gelir */
  transform: translateY(0);  /* Menüyü normal konumuna getir */
  z-index: 999;  /* Menü üstte olacak */
}
.menu-item a {
  text-decoration: none;
  color: white;
  position: relative;

}

.menu-item a::before {
  content: ''; /* Çizgiyi ekleyeceğiz */
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; /* Başlangıçta çizgi genişliği 0 */
  height: 2px; /* Çizgi yüksekliği */
  background-color: #B0B0B0; /* Çizgi rengi */
  transform: scaleX(0); /* Başlangıçta çizgi görünmesin */
  transform-origin: bottom right; /* Başlangıç noktası */
  transition: transform 0.4s ease-in-out; /* Geçiş süresi ve tipi */
}

.menu-item a:hover::before {
  transform: scaleX(1); /* Hover olduğunda çizgi genişlesin */
  transform-origin: bottom left; /* Çizginin sağdan sola genişlemesi için */
}
.sign-up a {
  background: linear-gradient(to left, #d7b56a 0%, #f5eeba 20.2%, #d9b96f 43%, #f5eebc 66.51%, #dcbe77 84.37%, #ecdda3 100%);
  background-size: 200% 100%; /* Gradient genişliği artırıldı */
  background-position: right center; /* Başlangıç pozisyonu */
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  padding: 5px 15px;
  text-decoration: none;
  transition: background-position 0.5s ease, transform 0.3s ease;
}

.sign-up a:hover {
  background-position: left center; /* Hover durumunda sola geçiş */
  transform: scale(1.05); /* Hafif büyüme efekti */
  color: #000; /* Daha parlak beyaz */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Hafif bir gölge */
}

.custom-div {
  margin-top: 50px;
  margin-left: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px; 
}

