/* components/About.css */

.about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    height: 50vh; 
  }
  
  .about-card {
    display: flex;
    justify-content: space-between; /* Sol ve sağ arasına boşluk bırakır */
    color: white; /* Yazı rengi beyaz */
    padding: 40px;
    border-radius: 10px;
    max-width: 1000px; /* Kartın genişliği */
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    gap: 60px;
  }
  
  .about-text {
    flex: 1; /* Yazı kısmı genişliği alır */
    text-align: left;
  }
  .about-text p {
    font-size: 1.2rem;
  }

  .about-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  
  .about-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .about-button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #1C427A; /* Material UI mavi rengi */
    color: white;
    border-radius: 5px;
    transition: all 0.4s ease-in-out; /* Tüm geçişleri daha uzun ve smooth yapmak için */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-button:hover {
    background-color: #0E213D; /* Hoverda daha koyu mavi */
    transform: translateY(-5px); /* Daha belirgin ve yumuşak yukarı kayma */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Hoverda gölgeyi de artır */
    transition: all 0.4s ease-in-out;
  }
  .about-button::after{
    transform: translateY(+5px);
  }
  
  
  .button-text {
    transition: opacity 0.3s ease;
    opacity: 1;
  }
  
  .info-icon {
    position: absolute;
    left: 40%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .about-button:hover .button-text {
    opacity: 0; /* Hoverda "Detaylar" yazısı kaybolur */
  }
  
  .about-button:hover .info-icon {
    opacity: 1; /* Hoverda ikon görünür hale gelir */
  }
/* Ayrıcalıklarımıza stil verelim */
.ayricaliklarimiz-container {
  text-align: center;
  margin: 50px 20px;
  padding: 40px;
  border: 2px solid rgba(0, 0, 0, 0.2); /* Saydam çerçeve */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1); /* Hafif saydam arka plan */
  backdrop-filter: blur(10px); /* Arka planı bulanıklaştırma */
  
  /* Flexbox ile içerik ortalama */
  display: flex;
  flex-direction: row; /* Yatayda sıralama */
  justify-content: center; /* Yatayda ortalama */
  align-items: center; /* Dikeyde ortalama */
  flex-wrap: wrap; /* Kutucuklar yandanda satırlara geçebilir */
}



/* Grid düzeni */
.features-grid {
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Her öğe için esnek kolonlar */
  gap: 20px;
  margin-top: 30px;
}

.feature-item {
  background-color: rgba(0, 0, 0, 0.2); /* Öğenin arka plan rengi */
  padding: 20px;
  border-radius: 10px;
  min-width: 200px;
  text-align: center; /* Metin ve ikonları ortala */
  transition: all 0.3s ease;
  color: white;
}

.feature-item:hover {
  background-color: rgba(0, 0, 0, 0.3); /* Hoverda arka plan rengi değişimi */
  transform: scale(1.05); /* Hoverda öğe büyür */
}

.icon {
  font-size: 30px; /* İkon boyutu */
  color: white;
}

/* Dış Çember */
.circle-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.outer-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #0E213D; /* Dış çemberin rengi */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: scaleOuter 2s infinite ease-in-out;
  transition: transform 0.3s ease; /* Hoverda dış çemberin animasyonu */
}

/* İç Çember */
.inner-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #1C427A; /* İç çemberin rengi */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hover animasyonu */
.outer-circle:hover {
  transform: scale(1.2); /* Hoverda dış çemberin büyümesi */
}

.inner-circle:hover {
  transform: scale(1.1); /* Hoverda iç çemberin büyümesi */
}

/* İkon Animasyonu */
.outer-circle:hover .icon,
.inner-circle:hover .icon {
  transform: scale(1.1); /* Hoverda ikon büyür */
  transition: transform 0.3s ease;
}
@media (max-width: 1100px) {
  .features-grid {
    justify-content: flex-start;
    display: block;
  }

  .feature-item {

    margin-bottom: 20px;
  }
}


@media (max-width: 600px) {
  .about-section{
    height: 30vh;
  }
  .about-card {
    flex-direction: column;
    gap: 20px;
  }
  .about-button-container {
    justify-content: center;
  }
  .about-text p {
    font-size: 0.875rem; /* Telefon modunda daha küçük font */
  }
}



  